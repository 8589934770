import axios from 'axios';
import { v4 } from 'uuid';

export const apiClient = axios.create({
  baseURL: "/api"
});

export const uuid = v4;

export const excelData = [
  { label: "Patient Name", field: "Name" },
  { label: "Hospital No", field: "HospNo" },
  { label: "Address", field: "Address" },
  { label: "Phone No", field: "PhoneNo" },
  { label: "Mother Age", field: "MatAge" },
  { label: "Mother Parity", field: "MatParity" },
  { label: "Mother Education", field: "MatEdu" },
  { label: "Mother Occupation", field: "MatOccupation" },
  { label: "Maternal Medical Conditions", field: "MatPregCond" },
  { label: "Previous Pregnancy Loss", field: "MatPregLoss" },
  { label: "Previous Neonatal Death", field: "MatPrevNeonatalDeath" },
  { label: "Maternal Illness on Admission", field: "MatIllnessOnAdmission" },
  { label: "ANC", field: "Anc" },
  { label: "ANC Four Visits", field: "AncFourVisits" },
  { label: "Place of ANC", field: "AncPlace" },
  { label: "Place of Delivery", field: "DeliveryPlace" },
  { label: "Mode of Delivery", field: "DeliveryMode" },
  { label: "C/S indication", field: "CSIndication" },
  { label: "Father Age", field: "PatAge" },
  { label: "Father Education", field: "PatEdu" },
  { label: "Father Occupation", field: "PatOccupation" },
  { label: "DOB", field: "BabyDob" },
  { label: "DOA", field: "DateOfAdmission" },
  { label: "Sex", field: "Sex" },
  { label: "Gestational Age (weeks)", field: "GAInWeeks" },
  { label: "Age at Admission (hours/days)", field: "AdmissionAge" },
  {
    label: "Time of Onset of Illness to Presentation in Facility",
    field: "IllnessDuration"
  },
  { label: "Birth Weight (Kg)", field: "BirthWeight" },
  { label: "Weight at Presentation (Kg)", field: "PresentationWeight" },
  { label: "Presenting Complaints", field: "PresentingComplaints" },
  { label: "General Examination", field: "GeneralExamination" },
  { label: "SPO2at Admission (%)", field: "SPO2OnAdmission" },
  { label: "OFC(cm)", field: "OFC" },
  { label: "Length(cm)", field: "OFCLength" },
  { label: "Head Lag", field: "HeadLag" },
  { label: "Suck", field: "Suck" },
  { label: "Respiratory Distress", field: "RespiratoryDistress" },
  { label: "Respiratory Evidence", field: "RespiratoryEvidence" },
  { label: "Respiratory Rate", field: "RespiratoryRate" },
  { label: "Heart Rate", field: "HeartRate" },
  { label: "Murmur", field: "Murmur" },
  { label: "Abdomen", field: "Abdomen" },
  { label: "PDA", field: "Pda" },
  { label: "BPD", field: "Bpd" },
  { label: "NEC", field: "Nec" },
  { label: "LONS", field: "Lons" },
  { label: "ROP", field: "Rop" },
  { label: "IVH", field: "Ivh" },
  { label: "AOP", field: "Aop" },
  { label: "CPAP", field: "Cpap" },
  { label: "MV", field: "Mv" },
  { label: "Others", field: "Others" },
  { label: "Surfactant", field: "Surfactant" },
  { label: "Caffeine", field: "Caffeine" },
  { label: "Mannitol", field: "Mannitol" },
  { label: "Temperature at Admission (°C)", field: "TempOnAdmission" },
  { label: "Initial Diagnosis at Admission I", field: "InitialDiagnosis1" },
  { label: "Initial Diagnosis at Admission II", field: "InitialDiagnosis2" },
  { label: "Initial Diagnosis at Admission III", field: "InitialDiagnosis3" },
  { label: "Initial Diagnosis at Admission IV", field: "InitialDiagnosis4" },
  { label: "Initial Diagnosis at Admission V", field: "InitialDiagnosis5" },
  { label: " Morbidities I", field: "MorbiditiesOnAdmission1" },
  { label: " Morbidities II", field: "MorbiditiesOnAdmission2" },
  { label: " Morbidities III", field: "MorbiditiesOnAdmission3" },
  { label: " Morbidities IV", field: "MorbiditiesOnAdmission4" },
  { label: " Morbidities V", field: "MorbiditiesOnAdmission5" },
  { label: "PCV on Admission(%)", field: "PCVOnAdmission" },
  { label: "RBS on Admission(mmoL/L)", field: "RBSOnAdmission" },
  { label: "FBC Result", field: "FBCResult" },
  { label: "PCV(%)", field: "PCV" },
  { label: "WBC Total(x10^9/L)", field: "WBCTotal" },
  { label: "Neut(%)", field: "Neut" },
  { label: "Lymph(%)", field: "Lymph" },
  { label: "Baso(%)", field: "Baso" },
  { label: "Eosin(%)", field: "Eosin" },
  { label: "Platelet(%)", field: "Platelete" },
  { label: "MCV(%)", field: "MCV" },
  { label: "MCHC(%)", field: "MCHC" },
  { label: "Blood Culture Result", field: "BloodCultureResult" },
  { label: "Investigation 1", field: "Investigation1" },
  { label: "Result 1", field: "Result1" },
  { label: "Investigation 2", field: "Investigation2" },
  { label: "Result 2", field: "Result2" },
  { label: "Blood Transfusions", field: "BloodTransfusion" },
  { label: "Number of Blood Transfusions", field: "BloodTransfusionNumber" },
  {
    label: "Indication for Blood Transfusions",
    field: "BloodTransfusionIndication"
  },
  { label: "Phototherapy during Admission", field: "Phototherapy" },
  { label: "Surgery during Admission", field: "Surgery" },
  { label: "Incubator Care", field: "IncubatorCare" },
  { label: "Antibiotic 1", field: "Antibiotic1" },
  { label: "Antibiotic Indication 1", field: "AntibioticIndication1" },
  { label: "Antibiotic Duration 1 (days)", field: "AntibioticDuration1" },
  { label: "Antibiotic 2", field: "Antibiotic2" },
  { label: "Antibiotic Indication 2", field: "AntibioticIndication2" },
  { label: "Antibiotic Duration 2 (days)", field: "AntibioticDuration2" },
  { label: "Antibiotic 3", field: "Antibiotic3" },
  { label: "Antibiotic Indication 3", field: "AntibioticIndication3" },
  { label: "Antibiotic Duration 3 (days)", field: "AntibioticDuration3" },
  { label: "Antibiotic 4", field: "Antibiotic4" },
  { label: "Antibiotic Indication 4", field: "AntibioticIndication4" },
  { label: "Antibiotic Duration 4 (days)", field: "AntibioticDuration4" },
  { label: "Antibiotic 5", field: "Antibiotic5" },
  { label: "Antibiotic Indication 5", field: "AntibioticIndication5" },
  { label: "Antibiotic Duration 5 (days)", field: "AntibioticDuration5" },
  { label: "Preterm", field: "Preterm" },
  {
    label: " if preterm, PNA @ oral feeds 120mls/kg",
    field: "PNABirthWeightRegain"
  },
  { label: "Discharge", field: "Discharge" },
  { label: "Weight at discharge (kg)", field: "WeightAtDischarge" },
  { label: "Postnatal age at discharge (days)", field: "PNAAtDischarge" },
  { label: "PCA at discharge", field: "PCAAtDischarge" },
  { label: "Final Diagnosis 1", field: "FinalDiagnosis1" },
  { label: "Final Diagnosis 2", field: "FinalDiagnosis2" },
  {
    label: "Duration of Admission till discharge/death (days)",
    field: "AdmissionDuration"
  },
  { label: "Outcome of Illness", field: "IllnessOutcome" },
  { label: "Created By", field: "created_by" },
  { label: "Modified By", field: "modified_by" },
  { label: "Created On", field: "created_on" },
  { label: "Modified On", field: "modified_on" }
];